import React from "react";
import "./Loader.css";

const Loader = (props) => {
  const customStyle = props?.style;
  return props.style !== undefined ? (
    <div id="cover-spin" style={customStyle}></div>
  ) : (
    <div id="cover-spin"></div>
  );
};

export default Loader;
